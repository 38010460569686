import React from "react"
import Template from "./template"
import PropTypes from "prop-types"
import Seo from "components/sections/seo"
import LOGO from "images/cortica-logo.svg"
import { graphql } from "gatsby"
const siteUrl = process.env.GATSBY_SITE_URL || "https://corticacare.com"

const TemplateContainer = ({ data: { template } }) => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    logo: LOGO,
    name: "Cortica Care",
    sameAs: [
      "https://twitter.com/CorticaCare",
      "https://www.facebook.com/CorticaCare",
      "https://www.linkedin.com/company/cortica-/",
      "https://www.instagram.com/corticacare/",
    ],
    url: siteUrl,
  }

  return (
    <>
      <Seo {...template.meta} schemaMarkup={schema} slug={template.slug} />
      <Template {...template} />
    </>
  )
}
TemplateContainer.props = {
  ...Template.props,
  meta: PropTypes.shape(Seo.props),
}
TemplateContainer.propTypes = {
  data: PropTypes.shape({
    template: PropTypes.shape(TemplateContainer.props),
  }),
}
export default TemplateContainer
export const query = graphql`
  query ResearchInterestFormTemplate($slug: String) {
    template: contentfulResearchInterestForm(slug: { eq: $slug }) {
      slug
      title
      htmlContent {
        htmlContent
      }
      meta: seoMetadata {
        canonical
        title
      }
    }
  }
`
