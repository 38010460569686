import React, { useEffect } from "react"
import PropTypes from "prop-types"
import "./researchInterestForm.scss"
import logo from "../../../../static/images/images/logo.svg"
const ResearchInterestForm = ({ htmlContent, title }) => {
  useEffect(() => {
    const executeInlineScripts = () => {
      const scriptElements = document.querySelectorAll(
        ".research-interest-form__content script"
      )
      scriptElements.forEach(oldScript => {
        const newScript = document.createElement("script")
        Array.from(oldScript.attributes).forEach(attr =>
          newScript.setAttribute(attr.name, attr.value)
        )
        newScript.textContent = oldScript.textContent
        document.body.appendChild(newScript)
        oldScript.parentNode.removeChild(oldScript)
      })
    }

    const externalScript = document.createElement("script")
    externalScript.src = "https://app.clinicalresearch.io/js/web-form.js"
    externalScript.async = true
    externalScript.onload = () => {
      executeInlineScripts()
    }
    externalScript.onerror = () => {
      console.error("Failed to load external script")
    }
    document.body.appendChild(externalScript)

    const link = document.createElement("link")
    link.rel = "stylesheet"
    link.href = "https://app.clinicalresearch.io/css/web-form.css"
    link.onerror = () => {
      console.error("Failed to load CSS")
    }
    document.head.appendChild(link)

    return () => {
      document.body.removeChild(externalScript)
      const links = document.querySelectorAll(
        `link[href="https://app.clinicalresearch.io/css/web-form.css"]`
      )
      links.forEach(link => link.parentNode.removeChild(link))
    }
  }, [])

  return (
    <div className="research-interest-form-page">
      <header className="research-interest-form__header">
        <img
          alt="Cortica Logo"
          className="research-interest-form__logo"
          src={logo}
        />
      </header>
      <section className="research-interest-form">
        <h1 className="research-interest-form__title">{title}</h1>
        <div
          className="research-interest-form__content"
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
      </section>
    </div>
  )
}

ResearchInterestForm.propTypes = {
  htmlContent: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default ResearchInterestForm
