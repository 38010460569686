import React from "react"
import PropTypes from "prop-types"
import ResearchInterestForm from "components/sections/researchInterestForm"

const Template = ({ htmlContent, title }) => {
  return (
    <div>
      <ResearchInterestForm
        htmlContent={htmlContent.htmlContent}
        title={title}
      />
    </div>
  )
}

Template.propTypes = {
  htmlContent: PropTypes.shape({
    htmlContent: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
}

export default Template
